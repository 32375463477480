(function (angular, _) {
    var MyHippoCommonDirectives = angular.module('MyHippoCommons.Directives');

    MyHippoCommonDirectives.directive('hpLiveAddress', hpLiveAddressDirective);
    hpLiveAddressDirective.$inject = ['$timeout', 'toaster', 'CommonAPIService'];
    function hpLiveAddressDirective ($timeout, toaster, CommonAPIService) {
        return {
            restrict: 'E',
            template: `
                <input
                    type="text"
                    class="form-control"
                    placeholder="Live address"
                    ng-model="addressStr"
                    uib-typeahead="address as address.text for address in findAddresses($viewValue)"
                    typeahead-on-select='selectAddress($item, $model, $label)'
                    typeahead-loading="loadingLocations"
                    typeahead-no-results="noResults"
                >
            `,
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                $scope.addressStr = '';
                $scope.filters = {state_filter: ''};

                $attrs.$observe('filterState', function (val) { $scope.filters.state_filter = val; });
                $attrs.$observe('reset', function (val) {
                    ngModelCtrl.$setValidity('invalidAddress', true);
                    ngModelCtrl.$setValidity('needsApartment', true);
                    $scope.addressStr = '';
                });

                $scope.findAddresses = function (prefix) {
                    return CommonAPIService.suggestAddress(prefix, $scope.filters);
                };

                $scope.selectAddress = function ($item, $model, $label) {
                    ngModelCtrl.$setValidity('invalidAddress', true);
                    ngModelCtrl.$setValidity('needsApartment', true);

                    CommonAPIService.validateAddress({
                        street: $model.street_line,
                        city: $model.city,
                        state: $model.state
                    }).then(function (res) {
                        var needsApartment = false;

                        if (!res || !res.length) {
                            ngModelCtrl.$setValidity('invalidAddress', false);
                            ngModelCtrl.$setViewValue({});
                            return;
                        }
                        var verifiedAddr = res[0];

                        if (verifiedAddr.analysis.dpv_match_code === 'D') {
                            ngModelCtrl.$setValidity('needsApartment', false);
                            needsApartment = true;
                        }

                        ngModelCtrl.$setViewValue({
                            street: verifiedAddr.delivery_line_1,
                            street2: verifiedAddr.delivery_line_2,
                            city: verifiedAddr.components.city_name,
                            county: verifiedAddr.metadata.county_name,
                            state: (verifiedAddr.components.state_abbreviation || '').toLowerCase(),
                            zip: verifiedAddr.components.zipcode,
                            needsApartment: needsApartment
                        });
                    });
                };
            }
        };
    }
})(window.angular, window._);